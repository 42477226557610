export const ModuleNav = [
	{
		icon: "icon-dashboard",
		label: "Dashboard",
		to: "#/dashboard/home",
	},
	{
		icon: "icon-service",
		label: "Service Request",
		to: "#/dashboard/service_request",
	},
	{
		icon: "icon-client",
		label: "Clients",
		to: "#/dashboard/clients",
	},
	{
		icon: "icon-dealer",
		label: "Dealers",
		to: "#/dashboard/dealers",
	},
	{
		icon: "icon-machine",
		label: "Machines",
		to: "#/dashboard/machines",
	},
	{
		icon: "icon-client",
		label: "QR Grouping",
		to: "#/dashboard/qr-grouping",
	},
	{
		icon: "icon-report",
		label: "Reporting",
		content: [
			{
				label: "Service Case Based On Technology Analysis",
				to: "#/dashboard/reportings/service_case_based_on_technology_analysis",
			},
			{
				label: "Machines Supplied Factories & Under Warranty Analysis",
				to: "#/dashboard/reportings/machines_supplied_factories_under_warranty_analysis",
			},
			{
				label: "Service Engineer Hour Contributions",
				to: "#/dashboard/reportings/service_engineer_hour_contributions",
			},
			{
				label: "Service Case based on Faulty Type Analysis",
				to: "#/dashboard/reportings/service_case_based_on_faulty_type_analysis",
			},
			{
				label: "Service Completion Rate",
				to: "#/dashboard/reportings/service_completion_rate",
			},
			{
				label: "Service Request Response Rate",
				to: "#/dashboard/reportings/service_request_response_rate",
			},
			{
				label: "Service Request (Chargeable under the particular service request)",
				to: "#/dashboard/reportings/chargeable_under_particular_request",
			},
			{
				label: "Engineer Attendance Report",
				to: "#/dashboard/reportings/engineer_attendance",
			},
		],
	},
];

export const ExternalEngineerNav = [
	{
		icon: "icon-dashboard",
		label: "Dashboard",
		to: "#/dashboard/home",
	},
	{
		icon: "icon-service",
		label: "Service Request",
		to: "#/dashboard/service_request",
	},
	{
		icon: "icon-report",
		label: "Reporting",
		content: [
			{
				label: "Service Case Based On Technology Analysis",
				to: "#/dashboard/reportings/service_case_based_on_technology_analysis",
			},
			{
				label: "Machines Supplied Factories & Under Warranty Analysis",
				to: "#/dashboard/reportings/machines_supplied_factories_under_warranty_analysis",
			},
			{
				label: "Service Engineer Hour Contributions",
				to: "#/dashboard/reportings/service_engineer_hour_contributions",
			},
			{
				label: "Service Case based on Faulty Type Analysis",
				to: "#/dashboard/reportings/service_case_based_on_faulty_type_analysis",
			},
			{
				label: "Service Completion Rate",
				to: "#/dashboard/reportings/service_completion_rate",
			},
			{
				label: "Service Request Response Rate",
				to: "#/dashboard/reportings/service_request_response_rate",
			},
			{
				label: "Service Request (chargeable under the particular service request)",
				to: "#/dashboard/reportings/chargeable_under_particular_request",
			},
		],
	},
]

export const AccountNav = [
	{
		icon: "icon-settings",
		label: "Settings",
		content: [
			{
				label: "Users",
				to: "#/dashboard/settings/users",
			},
			{
				label: "External Engineers",
				to: "#/dashboard/settings/external_engineers",
			},
			{
				label: "Machine Models",
				to: "#/dashboard/settings/machine_models",
			},
			{
				label: "Controllers",
				to: "#/dashboard/settings/controllers",
			},
			{
				label: "Tools",
				to: "#/dashboard/settings/tools",
			},
			{
				label: "Factory",
				to: "#/dashboard/settings/factory",
			},
			{
				label: "NC",
				to: "#/dashboard/settings/nc",
			},
			{
				label: "Technology",
				to: "#/dashboard/settings/technology",
			},
			{
				label: "Service Types",
				to: "#/dashboard/settings/service_types",
			},
		],
	},
	{
		icon: "icon-log-out",
		label: "Logout",
		to: "#/login?action=clear-token",
	},
];
