import React, { Component } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import _ from "lodash";

import { setEnableMobileMenu } from "reducers/ThemeOptions";

import { ModuleNav, AccountNav, ExternalEngineerNav } from "./dataMainNav";
import './index.scss'

class VerticalNavWrapper extends Component {
  render = () => {
    return (
      <>
        <h2 className="app-sidebar__heading">Module</h2>
        <MetisMenu
          content={
            this.props.data.ProfileReducer.profile.role.indexOf('engineer') < 0
              ? [ 
                ... ModuleNav,
                ... this.props.data.ProfileReducer.profile.role.indexOf('dealer') < 0 ? [
                  {
                    icon: "icon-client",
                    label: "Attendance",
                    to: "#/dashboard/attendance",
                  },
                ] : []
              ]
              : ExternalEngineerNav
          }
          onSelected={() =>
            this.props.setEnableMobileMenu(!this.props.enableMobileMenu)
          }
          activeLinkFromLocation
          className="vertical-nav-menu module-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />

        <h2 className="app-sidebar__heading">My Account</h2>
        <MetisMenu
          content={
            this.props.data.ProfileReducer.profile.role.indexOf('engineer') < 0
              ? AccountNav
              : [
                {
                  icon: "icon-log-out",
                  label: "Logout",
                  to: "#/login?action=clear-token",
                },
              ]
          }
          onSelected={(e) => {
            if (e.target.innerText === "Logout") {
            }
            this.props.setEnableMobileMenu(!this.props.enableMobileMenu)
          }}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenum,
  data: state,
});

export default connect(mapStateToProps, {
  setEnableMobileMenu,
})(VerticalNavWrapper);
